<script setup>
import { useLocalizedStoryParams } from '@/composables/useLocalizedStoryParams'
const { getParams } = useLocalizedStoryParams()
const story = await useAsyncStoryblok('home', getParams()).catch(() => {})

useHead({
  title: story.value.content.meta_title,
  meta: [{ name: 'description', content: story.value.content.meta_description }],
})
</script>

<template>
  <StoryblokComponent v-if="story" :blok="story.content" />
</template>
