export function useLocalizedStoryParams() {
  function getParams({ withFallback = false }: { withFallback?: boolean } = {}) {
    const config = useRuntimeConfig()

    const storiesParams = {
      ...config.public.storyblok.apiOptions,
      // cv: space.version,
    }

    return storiesParams
  }

  return {
    getParams,
  }
}
